<template>
  <div class="square-container createScene">
    <div class="square-top">
      <user-info />
    </div>
    <div class="square-box">
      <router-link to="square">
        <img src="../assets/images/logo2.png" alt="logo" class="square-logo">
      </router-link>
      <h5 class="choice-scene">选择想要创建的场景</h5>
      <div class="square-tab">
        <div class="tab-pane">
          <div class="pane-item" :class="!tags?'active':''" @click="changCate()">全部</div>
          <div class="pane-item" :class="item.title==tags?'active':''" @click="changCate(item.title)" v-for="item in cate" :key="item.id">{{item.title}}</div>
        </div>
        <div class="tab-content">
          <div class="content-item" v-show="type==1">
            <div class="explore-item" v-for="item in data" :key="item.scene_listing_id" @click="createRoom(item.scene_listing_id)">
              <img v-lazy="item.pic" class="explore-bcg" alt="图片" />
              <h5 class="leftBtm headline">{{ item.name }}</h5>
              <p class="leftBtm txt" :title="item.txt">{{ item.introduction }}</p>
            </div>
          </div>
          <div class="content-item" v-show="type==2">
            <div class="explore-item" v-for="item in data" :key="item.id">
              <img v-lazy="item.pic" class="explore-bcg" alt="图片" />
              <h5 class="leftBtm headline">{{ item.name }}</h5>
              <p class="leftBtm txt" :title="item.txt">{{ item.introduction }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userInfo from '@/components/userInfo/index.vue'
import { getSceneList, getCateList } from '@/api/api'
export default {
  name: "square",
  components: {
    userInfo
  },
  data() {
    return {
      isShowOperate: false,
      isShowInfo: false,
      tags:'',
      cate: [],
      type:1,
      paginate:1000,
      data: []
    }
  },
  methods: {
    getList() {
      const params = {
        tags: this.tags,
        page: this.page,
        paginate: this.paginate
      }
      getSceneList(params).then(res => {
        if (res.code == 200) {
          this.data = res.data.data
        }

      })
    },
    getCateList() {
      const params = {
        paginate: this.paginate
      }
      getCateList(params).then(res => {
        if (res.code == 200) {
          this.cate = res.data.data
        }

      })
    },
    changCate(e){
      this.tags = e
      this.changeParams({ tags: this.tags })
    },
    createRoom(e){
      this.$router.push({ path: 'sceneInfo?id='+e })
    },
    changeParams(obj) {
      this.page = obj.page || 1
      const query = Object.assign({}, this.$route.query, obj)
      this.$router.push({
        path: this.$route.path,
        query: query
      })
      this.getList()
    },
    showOperate() {
      this.isShowOperate = !this.isShowOperate
    },
    showInfo() {
      this.isShowInfo = !this.isShowInfo
    },
    // 创建场景
    createScene() {
      this.$router.push({ path: "/wxLogin" });
    },
    goUserInfo() {
      this.$router.push({ path: "/user" });
    },
    goMyShop() {
      this.$router.push({ path: "/wxLogin" });
    }
  },
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.page = query.page ? query.page - 0 : 1
      this.tags = query.tags ? query.tags : ''
      this.getList()
      this.getCateList()
    })
  }
}

</script>
<style scoped lang="scss">
@import '../assets/css/square.scss';

</style>
