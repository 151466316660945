<template>
  <div class="userInfo-box">
    <!-- 未登录 -->
    <div class="header-box" v-if="!avatar">
      <img src="../../assets/images/loginImg.png" alt="" />
      <span>请登录</span>
    </div>
    <!-- 已登陆 -->
    <div class="header-box has-login" v-else>
      <div class="login-data" @click="showInfo">
        <img :src="avatar" />
        <span>{{name}}</span>
      </div>
      <ul class="top-info" v-show="isShowInfo">
        <li @click="goUserInfo">个人资料</li>
        <!-- <li @click="goMyShop">我的商品</li> -->
        <li class="sign-out" @click="logout">退出登录</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "userInfo",
  computed: {
    ...mapGetters([
      'avatar',
      'name'
    ])
  },
  data() {
    return {
      isLogin: true,
      isShowInfo: false,
    };
  },
  methods: {
    showInfo() {
      this.isShowInfo = !this.isShowInfo;
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/home`)
    },
    goUserInfo() {
      this.$router.push({ path: "/user" });
    },
    goMyShop() {
      window.open(`https://www.metaio.cc/admin`);
      // this.$router.push({ path: "/wxLogin" });
    },
  },
};
</script>

<style scoped lang="scss">
.userInfo-box {
  .header-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 164.79px;
    // padding: 0 15px;
    height: 80px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 41px;
    cursor: pointer;
    &.has-login {
      width: 244px;
      justify-content: space-around;
      position: relative;
      z-index: 3;
      .top-info {
        position: absolute;
        top: 0;
        left: 0;
        background: #fefefe;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 41px;
        width: 198px;
        height: 176px;
        padding: 120px 23px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        li {
          width: 100%;
          font-size: 30px;
          color: #1c1d22;
          &.sign-out {
            border-top: 1px solid #999;
            padding-top: 20px;
          }
        }
      }
      .login-data {
        display: flex;
        align-items: center;
        z-index: 3;
        width: 100%;
        overflow: hidden;
        img {
          width: 67px;
          height: 68px;
          margin: 0 15px;
        }
      }
    }
    img {
      width: 22px;
      height: 28px;
      padding-top: 5px;
    }
    span {
      font-weight: 700;
      font-size: 24px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-left: 14px;
    }
  }
}
</style>
